import { h } from 'preact'
import { useContext } from 'preact/hooks'

import Essentials from '../../contexts/essentials'
import Navbar from './navbar'

const Header = () => {
  const { name, headerLinks } = useContext(Essentials)
  return (
    <header class='is-relative has-box-shadow has-border-bottom has-border-grey-lighter'>
      <Navbar name={name} links={headerLinks} />
    </header>
  )
}

export default Header
