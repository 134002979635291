import { h, Component } from 'preact'

import { SearchFiltersService } from '@janiis/sites-theme-support'

import SearchFormForm from './form'

class SearchForm extends Component {
  state = {
    loading: false,
    error: null,
    data: null
  }

  componentWillMount () {
    this.searchFiltersService = new SearchFiltersService()
    this.loadFilters()
  }

  componentWillUnmount () {
    if (this.state.loading) this.searchFiltersService.abort()
  }

  loadFilters () {
    if (this.state.loading) return

    this.setState({ loading: true, error: null, data: null })

    this.searchFiltersService.fetch().then(data => {
      this.setState({ loading: false, data })
    }).catch(error => this.setState({ loading: false, error }))
  }

  render (props, { data, loading, error }) {
    return (<SearchFormForm filters={data} {...props} />)
  }
}

export default SearchForm
