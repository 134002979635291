import { h } from 'preact'

import SocialIconLink from './social_icon_link'

const SocialIconLinks = ({ socialLinks }) => {
  const socialIconLinks = Object.keys(socialLinks).map(network => {
    const link = socialLinks[network].url
    if (link) return (<li key={network}><SocialIconLink network={network.toLowerCase()} link={link} /></li>)
  })

  return (
    <nav class='footer-nav social-nav' role='navigation' aria-label='Social navigation'>
      <ul>
        {socialIconLinks}
      </ul>
    </nav>
  )
}

export default SocialIconLinks
