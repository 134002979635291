import { h } from 'preact'

const Switch = ({ id, name, label, onchange, checked }) => {
  return (
    <div class='field'>
      <input id={id} type='checkbox' name={name} class='switch' onchange={onchange} checked={checked} />
      <label for={id}>{label}</label>
    </div>
  )
}

export default Switch
