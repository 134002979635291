import { h } from 'preact'
import { useContext } from 'preact/hooks'

import SearchForm from '../../components/search_form'
import Essentials from '../../contexts/essentials'
import SocialIconLinks from './social_icon_links'
import FooterNav from './footer_nav'
import ContactInfo from './contact_info'

const Footer = () => {
  const { footerLinks, social, address, email, phone } = useContext(Essentials)
  return (
    <>
      <section class='section is-small is-hidden-mobile has-border-bottom has-border-grey-lighter'>
        <div class='container center-content' style='width: 50em'>
          <SearchForm oneLine resetOnSubmit />
        </div>
      </section>
      <footer class='footer'>
        <FooterNav links={footerLinks} />
        <SocialIconLinks socialLinks={social} />
        <ContactInfo {...address} email={email} phone={phone} />
      </footer>
    </>
  )
}

export default Footer
