import { h } from 'preact'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Guests = ({ id, name, max, oninput, value, placeholder, showLabel }) => {
  return (
    <div id='dates-guests' class='field'>
      <label class='label' for={id}>Guests</label>
      <p class='control has-icons-left'>
        <input id={id} name={name} class='input has-border has-border-primary theme-b-radius text-right' type='number' min='1' max={max} oninput={oninput} value={value} />
        <span class='icon is-small is-left'>
          <FontAwesomeIcon icon='user' />
        </span>
      </p>
    </div>
  )
}

export default Guests
