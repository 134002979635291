import { h } from 'preact'
import { useContext } from 'preact/hooks'

import { imgix as imgixSrc } from '@janiis/sites-theme-support'
import Essentials from '../../contexts/essentials'

const Logo = () => {
  const { name, logo, imgix } = useContext(Essentials)
  const maxWidth = 300
  const maxHeight = 70
  const src = imgixSrc(logo, { width: maxWidth, height: maxHeight, fit: 'clip' }, imgix)
  const isSvg = /\.svg$/.test(logo)
  const imgStyle = `max-width: ${maxWidth}px; max-height: ${maxHeight}px;`
  const aStyle = isSvg ? `width: ${maxWidth}px;` : ''

  return (
    <a class='navbar-brand-logo' href='/' style={aStyle}>
      <img class={isSvg && 'svg'} src={src} alt={name} style={imgStyle} />
    </a>
  )
}

export default Logo
