import { h } from 'preact'

const Select = ({ id, name, label, data, onchange, selected }) => {
  const options = data.map(([value, name]) => {
    return (<option key={value} value={value} selected={selected === value}>{name}</option>)
  })
  options.unshift((<option />))

  return (
    <div class='field'>
      <label class='label' for={id}>{label}</label>
      <div class='control'>
        <div class='select is-fullwidth'>
          <select id={id} name={name} onchange={onchange}>
            {options}
          </select>
        </div>
      </div>
    </div>
  )
}

export default Select
