import { h, Component } from 'preact'
import { Router } from 'preact-router'

import Header from '../header'
import Footer from '../footer'

// Code-splitting is automated for routes
import Home from '../../routes/home'
import Search from '../../routes/search'
import Listing from '../../routes/listing'
import Reserve from '../../routes/reserve'
import Page from '../../routes/page'

import Essentials from '../../contexts/essentials'
import { setCurrency } from '@janiis/sites-theme-support'

class AppLoaded extends Component {
  handleRoute = (event) => {
    window.scrollTo(0, 0)
  }

  render ({ essentials }) {
    setCurrency(essentials.currency == null ? 'USD' : essentials.currency)
    return (
      <Essentials.Provider value={essentials}>
        <div id='app'>
          <a class='is-sr-only' href='#skip-to-main-content'>Skip to main content</a>
          <Header />
          <main id='skip-to-main-content'>
            <Router onChange={this.handleRoute}>
              <Home path='/' />
              <Search path='/search' />
              <Listing path='/:unitSlug/listing/:listingId' />
              <Reserve path='/:unitSlug/listing/:listingId/reserve' />
              <Listing path='/:propertySlug/:unitSlug/listing/:listingId' />
              <Reserve path='/:propertySlug/:unitSlug/listing/:listingId/reserve' />
              <Page {...this.props} default />
            </Router>
          </main>
          <Footer />
        </div>
      </Essentials.Provider>
    )
  }
}

export default AppLoaded
