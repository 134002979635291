import { library } from '@fortawesome/fontawesome-svg-core'
import { faTwitter, faFacebook, faInstagram, faPinterest, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import {
  faMapMarkerAlt,
  faCalendar,
  faUser,
  faDollarSign,
  faStar,
  faImage,
  faTag
} from '@fortawesome/free-solid-svg-icons'

library.add(faTwitter, faFacebook, faInstagram, faPinterest, faLinkedin, faMapMarkerAlt, faCalendar, faUser, faDollarSign, faStar, faImage, faTag)
