import lscache from 'lscache'
import './style'
import App from './components/app'
import './fontawesome'
import 'delayed-scroll-restoration-polyfill'

lscache.setExpiryMilliseconds(1000) // using seconds for cache expiration

// We'll handle the scroll restoration manually
if ('scrollRestoration' in window.history) {
  window.history.scrollRestoration = 'manual'
}

export default App
