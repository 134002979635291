import { h } from 'preact'

const AppError = ({ error }) => {
  console.log(error)
  return (
    <div id='app'>
      <main>
        <section class='section'>
          <div class='container'>
            <div class='content'>
              <h1>Oh no!</h1>
              <p>We've encountered an unexpected error and can't continue.</p>
            </div>
          </div>
        </section>
      </main>
    </div>
  )
}

export default AppError
