import { h } from 'preact'

import ReactSlider from 'react-slider'

const Rates = ({ rates, onchange, rateMin, rateMax }) => {
  const [rateMinBoundary, rateMaxBoundary] = rates
  rateMin = parseInt(rateMin, 10) || rateMinBoundary
  rateMax = parseInt(rateMax, 10) || rateMaxBoundary

  return (
    <div class='field'>
      <label class='label' for=''>Rates</label>
      <div class='control'>
        <ReactSlider
          className='input horizontal-slider'
          thumbClassName='horizontal-slider-thumb button'
          trackClassName='horizontal-slider-track'
          min={rateMinBoundary}
          max={rateMaxBoundary}
          value={[rateMin, rateMax]}
          ariaLabel={['Minimum Rate', 'Maximum Rates']}
          ariaValuetext={state => `Rate ${state.valueNow}`}
          renderThumb={(props, state) => <div {...props}><div class='value'>${state.valueNow}</div></div>}
          pearling
          minDistance={35}
          onAfterChange={onchange}
        />
      </div>
    </div>
  )
}

export default Rates
