import { h, Component } from 'preact'

const loadingMessageDelay = 3000

class AppLoading extends Component {
  state = {
    showLoading: false
  }

  componentDidMount () {
    this.timeout = setTimeout(() => {
      this.setState({ showLoading: true })
    }, loadingMessageDelay)
  }

  componentWillUnmount () {
    if (this.timeout) clearTimeout(this.timeout)
  }

  render (_, { showLoading }) {
    if (showLoading) {
      return (
        <div id='app'>
          <main>
            <section class='section'>
              <div class='container'>
                <div class='content'>
                  <h1>Loading...</h1>
                  <p>Sorry, this is taking a little longer than expected to load.</p>
                </div>
              </div>
            </section>
          </main>
        </div>
      )
    } else {
      return (<div id='app' />)
    }
  }
}

export default AppLoading
