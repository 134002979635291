import { h } from 'preact'

import FooterNavLink from './footer_nav_link'

const FooterNav = ({ links }) => {
  const navLinks = links.map(link => (<FooterNavLink key={link.label} {...link} />))
  return (
    <nav class='footer-nav' role='navigation' aria-label='secondary navigation'>
      <ul>
        {navLinks}
      </ul>
    </nav>
  )
}

export default FooterNav
