import { h } from 'preact'

import AddressLine from '../address_line'

const ContactInfo = ({ email, phone, street1, street2, city, state, postal }) => {
  return (
    <div class='has-text-centered'>
      {email && <p class='has-margin-7-y'><a href={`mailto:${email}`}>{email}</a></p>}
      {phone && <p class='has-margin-7-y'><a href={`tel:${phone}`}>{phone}</a></p>}
      <AddressLine icon={false} street1={street1} street2={street2} city={city} state={state} postal={postal} />
    </div>
  )
}

export default ContactInfo
