import { h } from 'preact'
import Multiselect from 'multiselect-react-dropdown'

const MultiSelect = ({ id, label, data, selected, onchange }) => {
  const handleSelect = (selectedList) => {
    const selectedValues = selectedList.map(item => item.value)
    onchange(selectedValues)
  }

  const handleRemove = (selectedList) => {
    const selectedValues = selectedList.map(item => item.value)
    onchange(selectedValues)
  }

  return (
    <div class='field'>
      <label class='label' for={id}>{label}</label>
      <div class='control'>
        <div class='select is-fullwidth'>
          <Multiselect
            id={id}
            options={data}
            selectedValues={data.filter(option => selected.includes(option.value))}
            onSelect={handleSelect}
            onRemove={handleRemove}
            displayValue='label'
          />
        </div>
      </div>
    </div>
  )
}

export default MultiSelect
