import { h } from 'preact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const AddressLine = ({ icon, className, street1, street2, city, state, postal }) => {
  if (!street1 || !city || !state || !postal) return
  icon = icon !== false
  const streetLine = [street1, street2].map(street => (street || '').trim()).join(' ')

  return (
    <p class={className} itemscope itemtype='http://schema.org/PostalAddress'>
      {icon && <span class='icon'><FontAwesomeIcon icon='map-marker-alt' /></span>}
      <span itemprop='streetAddress'>{streetLine.trim()}</span>
      <span>, </span>
      <span itemprop='addressLocality'>{city.trim()}</span>
      <span>, </span>
      <span itemprop='addressRegion'>{state.trim()}</span>
      <span> </span>
      <span itemprop='postalCode'>{postal.trim()}</span>
    </p>
  )
}

export default AddressLine
