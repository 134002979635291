import { h, Component, createRef } from 'preact'

import flatpickr from 'flatpickr'

import { didPropsChange } from '@janiis/sites-theme-support'

class Flatpickr extends Component {
  showMonths = 1
  open = false

  componentWillMount () {
    this.inputRef = createRef()

    this.mediaQueryList = window.matchMedia('(min-width: 666px)')
    this.mediaQueryList.addListener(this.checkMediaQueryMatches)
    this.checkMediaQueryMatches()
  }

  componentDidMount () {
    this.buildFlatpickr()
  }

  componentDidUpdate (prevProps) {
    if (didPropsChange(prevProps, this.props, ['minDate'])) {
      this.flatpickr.set('minDate', this.props.minDate)
    }

    if (didPropsChange(prevProps, this.props, ['startDate', 'endDate', 'date'])) {
      const defaultDate = this.props.mode === 'range' ? [this.props.startDate, this.props.endDate] : this.props.date
      this.flatpickr.setDate(defaultDate, true)
    }

    this.flatpickr.set('disable', this.props.disable || [])
    this.flatpickr.set('enable', this.props.enable || [])
  }

  componentWillUnmount () {
    if (this.flatpickr) this.flatpickr.destroy()
    this.mediaQueryList.removeListener(this.checkMediaQueryMatches)
  }

  checkMediaQueryMatches = () => {
    const showMonths = this.mediaQueryList.matches ? 2 : 1
    if (showMonths === this.showMonths) return
    this.showMonths = showMonths
    // Have to rebuild the datepicker so it resets the outer width
    if (this.flatpickr) this.buildFlatpickr(true)
  }

  buildFlatpickr (open) {
    if (this.flatpickr) this.flatpickr.destroy()
    open = open && this.open

    const mode = this.props.mode || 'single'
    const defaultDate = mode === 'range' ? [this.props.startDate, this.props.endDate] : this.props.date

    this.flatpickr = flatpickr(this.inputRef.current, {
      dateFormat: 'M d, Y',
      disableMobile: true,
      mode,
      shorthandCurrentMonth: true,
      showMonths: this.showMonths,
      defaultDate,
      minDate: this.props.minDate || 'today',
      maxDate: null,
      onChange: this.handleChange,
      onClose: this.handleClose,
      onOpen: this.handleOpen,
      // onReady: this.handleReady,
      disable: this.props.disable || [],
      enable: this.props.enable || []
    })

    if (open) this.flatpickr.open()
  }

  handleChange = (selectedDates, dateStr, instance) => {
    // console.debug('flatpickr.onChange', selectedDates, dateStr)
    const { onChange } = this.props
    if (onChange) onChange(selectedDates, dateStr, instance)
  }

  handleClose = (selectedDates, dateStr, instance) => {
    // console.debug('flatpickr.onClose', selectedDates, dateStr)
    this.open = false
  }

  handleOpen = (selectedDates, dateStr, instance) => {
    // console.debug('flatpickr.onOpen', selectedDates, dateStr)
    this.open = true
  }

  // handleReady = (selectedDates, dateStr, instance) => {
  //   console.debug('flatpickr.onReady', selectedDates, dateStr)
  // }

  render ({ id, name, placeholder, classNames }) {
    return (
      <input ref={this.inputRef} type='text' name={name} placeholder={placeholder} id={id} class={classNames} />
    )
  }
}

export default Flatpickr
