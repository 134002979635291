import { h, Component } from 'preact'

import { EssentialsService } from '@janiis/sites-theme-support'

import { generateThemeColors } from '../../lib/helpers'

import AppLoading from './loading'
import AppError from './error'
import AppLoaded from './loaded'

export default class App extends Component {
  state = {
    loading: false,
    error: null,
    essentials: null
  }

  componentWillMount () {
    this.essentialsService = new EssentialsService()
    this.loadEssentials()
  }

  componentWillUnmount () {
    if (this.state.loading) this.essentialsService.abort()
  }

  loadEssentials () {
    if (this.state.loading) return

    this.setState({ loading: true, error: null, essentials: null })

    this.essentialsService.fetch().then(essentials => {
      this.setState({ loading: false, essentials }, () => { this.addThemeStyles() })
    }).catch(error => this.setState({ loading: false, error }))
  }

  addThemeStyles () {
    if (this.styleNode) this.styleNode.parent.removeChild(this.styleNode)

    this.styleNode = document.createElement('style')
    const styles = document.createTextNode(generateThemeColors(this.state.essentials.expandedThemeColors))
    this.styleNode.appendChild(styles)
    document.head.appendChild(this.styleNode)
  }

  render (_, { essentials, loading, error }) {
    if (loading) {
      return (<AppLoading />)
    } else if (error) {
      return (<AppError error={error} />)
    } else {
      return (<AppLoaded essentials={essentials} />)
    }
  }
}
