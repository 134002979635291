import { h } from 'preact'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Flatpickr from '../../flatpickr'

const Dates = ({ id, name, checkInDate, checkOutDate, onChange, placeholder, showLabel }) => {
  return (
    <div id='dates-guests' class='field'>
      <label class='label' for={id}>Dates</label>
      <p class='control has-icons-left'>
        <Flatpickr id={id} name={name} classNames='input has-border has-border-primary theme-b-radius text-right' startDate={checkInDate} endDate={checkOutDate} onChange={onChange} mode='range' />
        <span class='icon is-small is-left'>
          <FontAwesomeIcon icon='calendar' />
        </span>
      </p>
    </div>
  )
}

export default Dates
